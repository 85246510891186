<template>
  <AppInputDate
    v-bind="{ ...$props, ...$attrs }"
    :year-label="labels.year"
    :month-label="labels.month"
    :day-label="labels.day"
    :input-error-messages="inputErrorMessages"
    v-on="$listeners"
    :input-format="inputFormat"
  />
  <!-- input-format="DD/MM/YYYY" -->
</template>

<script>
import { computed, reactive } from '@vue/composition-api'

import { AppInputDate } from '@oen.web.vue2/ui'
import { getAllInfoByISO } from 'iso-country-currency'

import { useAuthStore, useI18nStore } from '@galileo/stores'

export default {
  name: 'RiaInputDate',
  components: {
    AppInputDate,
  },
  props: {
    shouldDisplayLabels: {
      type: Boolean,
      requried: false,
      default: true,
    },
  },
  setup(props) {
    const { $t } = useI18nStore()
    const authStore = useAuthStore()

    const inputErrorMessages = computed(() => {
      return {
        day: $t('ComponentRiaInputDate.InputErrorMessage.Day'),
        month: $t('ComponentRiaInputDate.InputErrorMessage.Month'),
        year: $t('ComponentRiaInputDate.InputErrorMessage.Year'),
        default: $t('ComponentRiaInputDate.InputErrorMessage.Default'),
      }
    })

    const labels = reactive({
      year: $t('ComponentRiaInputDate.Label.Year').value,
      month: $t('ComponentRiaInputDate.Label.Month').value,
      day: $t('ComponentRiaInputDate.Label.Day').value,
    })

    if (!props.shouldDisplayLabels) {
      labels.year = ''
      labels.month = ''
      labels.day = ''
    }

    let inputFormat = 'MM/DD/YYYY'

    let user = authStore.user
    if (user) {
      const userCountry = user.country
      const dateFormat = getAllInfoByISO(userCountry).dateFormat.toUpperCase()
      let dateSplit = dateFormat.split('/')

      dateSplit.forEach((slot, index) => {
        let type = slot[0]

        if (type === 'Y') {
          slot = slot.padStart(4, 'Y')
        } else {
          slot = slot.padStart(2, type)
        }

        dateSplit[index] = slot
      })

      inputFormat = `${dateSplit[0]}/${dateSplit[1]}/${dateSplit[2]}`
    }

    return {
      $t,
      inputErrorMessages,
      inputFormat,
      labels,
    }
  },
}
</script>

<style scoped></style>
